/**
 * astorage theme custom JS main file
 */
jQuery(document).ready(function () {

	/**
	 * Footer Slider
	 */
	if( $('#reasons').length ) {
		$('#reasons').slick({
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			dots: false,
			autoplay: true,
			autoplaySpeed: 1000,
			arrows: false,
			variableWidth: true,
			responsive: [
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 2,
				  }
				},
				{
				  breakpoint: 720,
				  settings: {
					slidesToShow: 1,
				  }
				},
			  ]
		});
	}


	/**
	 * Mobile Menu Trigger & Function
	 */
	const burgerMenubtn = $('#burger');	
	const mobileNav = $('.mobile-slide-menu');
	const closeButton = $('.close'); 

	burgerMenubtn.click(function(){
		if(mobileNav.hasClass('mobile_nav_active')){
			mobileNav.removeClass('mobile_nav_active');
		} else {
			mobileNav.addClass('mobile_nav_active');
		}
	});
	closeButton.click(function(){
		if(mobileNav.hasClass('mobile_nav_active')){
			mobileNav.removeClass('mobile_nav_active');
		} else {
			mobileNav.addClass('mobile_nav_active');
		}
	});


	//accordion
	if( $('.faqs').length ) {
		if( $('.accordion').length ) {
		  var allPanels = $('.accordion dd').hide();
			
		  $('.accordion dt > a').click(function() {

			var accordionItem = $(this).parents('.accordion_item');

			if(accordionItem.hasClass('active')){
				accordionItem.removeClass('active');
			} else {
				accordionItem.addClass('active');
			}

			if($(this).hasClass('active')){
			  $(this).parent().next().slideUp();
			  $(this).removeClass('active');
			} else {
			  allPanels.slideUp();
			  $('.accordion dt > a').removeClass('active');
			  $(this).addClass('active');
			  $(this).parent().next().slideDown();
			  return false;
			}

		  });
		}
	  }

	  if( $('.review-slider').length ) {
		$('.review-slider').slick({
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			dots: false,
			autoplay: true,
			arrows: false,
			variableHeight: true, // Set variableHeight to true
			responsive: [
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 2,
				  }
				},
				{
				  breakpoint: 720,
				  settings: {
					slidesToShow: 1,
				  }
				},
			  ]
		});
	}
	// Assuming the arrows are inside the same container as the slider
    var $slider = $('.review-slider');
    
    // Assuming the arrow spans have the classes .arrow-left and .arrow-right
    $('.arrow-left').on('click', function () {
        $slider.slick('slickPrev');
    });

    $('.arrow-right').on('click', function () {
        $slider.slick('slickNext');
    });

});

$(document).ready(function() {
    $('.pricing-arrow-left').click(function() {
        var wrapper = $('.pricing-table-inner-wrapper');
        wrapper.scrollLeft(wrapper.scrollLeft() - 300); // Adjust 300 based on column width
    });
    $('.pricing-arrow-right').click(function() {
        var wrapper = $('.pricing-table-inner-wrapper');
        wrapper.scrollLeft(wrapper.scrollLeft() + 300); // Adjust 300 based on column width
    });
});

// $(document).ready(function () {
// 	var maxCharacters = 100;

// 	$('.info p').each(function () {
// 		var $p = $(this);
// 		var content = $p.text();

// 		if (content.length > maxCharacters) {
// 			var truncatedContent = content.slice(0, maxCharacters) + '...';
// 			$p.text(truncatedContent);
// 			$('<div class="toggle-button">Toggle</div>').insertAfter($p);
// 			$('.toggle-button').on('click', function () {
// 				$p.text(content);
// 				$(this).hide();
// 			});
// 		}
// 	});
// });

// $(document).ready(function () {
// 	var maxCharacters = 200; // Adjust the value as needed
// 	var $info = $('.info');
// 	var combinedContent = '';

// 	$info.find('p').each(function () {
// 		combinedContent += $(this).text() + ' ';
// 	});

// 	if (combinedContent.length > maxCharacters) {
// 		var truncatedContent = combinedContent.slice(0, maxCharacters) + '...';
// 		$info.text(truncatedContent);
// 		$('<div class="toggle-button">Toggle</div>').insertAfter($info);
// 		$('.toggle-button').on('click', function () {
// 			$info.toggleClass('expanded');
// 		});
// 	}
// });